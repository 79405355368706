<template>
  <div id="login">
    <header class="flex_jc_center">
      <div class="w1200 top">
        <img
          src="@/assets/images/logo.png"
          alt="正品汇认证交易平台"
          class="logo"
          @click="$router.push('/Home')"
        />
        <div class="title" @click="$router.push('/Home')">
          <h1>正品汇认证交易平台</h1>
          <h2>Zhengpinhui authentication trading platform</h2>
        </div>
        <p>欢迎{{ $route.name != "login" ? "注册" : "登录" }}！</p>

        <div class="go_login">
          <span>客服电话：0755-33297563</span>
          <p v-if="$route.name != 'login'">
            已注册可<a href="#/Login">直接登录</a>
          </p>
        </div>
      </div>
    </header>
    <main v-if="$route.name === 'login'" class="flex_jc_center">
      <div class="w1200 login_box">
        <img src="@/assets/images/login_bg.png" alt="" />

        <section>
          <div class="method">
            <p>账户登录</p>
          </div>

          <div class="login_cont">
            <el-form :model="ruleForm" :rules="rules" ref="ruleForm">
              <div v-show="method">
                <el-form-item prop="accountNumber">
                  <el-input
                    placeholder="请输入账号"
                    v-model="ruleForm.accountNumber"
                    :validate-event="false"
                  >
                    <template slot="prepend"
                      ><svg-icon icon-class="login_user"
                    /></template>
                  </el-input>
                </el-form-item>
                <el-form-item prop="password">
                  <el-input
                    placeholder="请输入密码"
                    v-model="ruleForm.password"
                    :type="passwordShow ? 'text' : 'password'"
                    :validate-event="false"
                    @keyup.enter.native="login"
                  >
                    <template slot="prepend"
                      ><svg-icon icon-class="login_password"
                    /></template>
                    <i slot="suffix" @click="passwordShow = !passwordShow">
                      <svg-icon
                        :icon-class="passwordShow ? 'ic-eyes' : 'ic-closeEyes'"
                      />
                    </i>
                  </el-input>
                </el-form-item>
              </div>
              <div v-show="!method">
                <el-form-item prop="number">
                  <el-input
                    placeholder="请输入手机号码"
                    v-model="ruleForm.number"
                    :validate-event="false"
                  >
                    <template slot="prepend"
                      ><svg-icon icon-class="login_user"
                    /></template>
                  </el-input>
                </el-form-item>
                <el-form-item prop="authCode">
                  <el-input
                    type="text"
                    v-model="ruleForm.authCode"
                    placeholder="请输入验证码"
                    maxlength="6"
                    :validate-event="false"
                  >
                    <template slot="prepend"
                      ><svg-icon icon-class="login_authCode"
                    /></template>
                    <template #append>
                      <p
                        @click="CaptchaMethod"
                        :style="
                          Captcha ? 'cursor: default;' : 'cursor: pointer;'
                        "
                      >
                        {{ Captcha ? Captcha : "获取验证码" }}
                      </p>
                    </template>
                  </el-input>
                </el-form-item>
              </div>
            </el-form>
            <div class="other_ctions">
              <a @click="method = !method">
                {{ method ? "短信验证" : "账号" }}登录</a
              >
              <router-link to="/Login/ForgetPassword">忘记密码？</router-link>
            </div>
            <!-- https://zph.origmall.cn/#/Agreement/TermsOfService.pdf -->
            <div class="clause">
              <input type="checkbox" v-model="clause" />
              <div>
                我已阅读并接受以下条款：<a
                  href="/Agreement/TermsOfService.pdf"
                  target="_blank"
                  >《服务条款》</a
                ><a href="/Agreement/PrivacyPolicy.pdf" target="_blank"
                  >《隐私政策》</a
                ><a href="/Agreement/UserServiceAgreement.pdf" target="_blank"
                  >《用户服务协议》</a
                >
              </div>
            </div>
            <button @click="login">登录</button>
          </div>
          <!-- <div class="sign_up">
            <a href="#/Login/Register"
              ><svg-icon icon-class="login_sign_up_arrow" />立即注册</a
            >
          </div> -->
        </section>
      </div>
    </main>
    <div ref="file"></div>
    <router-view></router-view>
    <footer class="flex_jc_center">
      <div class="w1200 bottom">
        <div class="introduction flex_sb">
          <div
            v-for="list in quality"
            :key="list.icon"
            class="introduction_list"
          >
            <svg-icon :icon-class="list.icon" />
            <div>
              <p>{{ list.title }}</p>
              <span>{{ list.content }}</span>
            </div>
          </div>
        </div>
        <nav class="flex_sb">
          <div v-for="list in bottomNav" :key="list.title" class="nav_list">
            <p>{{ list.title }}</p>
            <a
              :href="item.url"
              v-for="item in list.content"
              :key="item.content"
              >{{ item.content }}</a
            >
          </div>
        </nav>
      </div>
    </footer>
    <div class="copyright">
      <span>版权所有2022</span>
      <span>正品汇认证交易平台</span>
      <span
        ><a
          target="_blank"
          href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44030402005832"
          ><img
            src="@/assets/images/copyright.png"
            style="float: left"
          />粤公网安备 44030402005832号</a
        ></span
      >
      <span
        ><a href="https://beian.miit.gov.cn/" target="_blank"
          >粤ICP备18094129号</a
        ></span
      >
      <span>增值电信业务经营许可证：粤B2-20191323</span>
    </div>
  </div>
</template>

<script>
import { getToken, setToken, removeToken } from "@/utils/auth";

export default {
  name: "login",

  data() {
    var validateNumber = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("手机号码不能为空"));
      } else if (
        !new RegExp(
          /^(?:(?:\+|00)86)?1(?:(?:3[\d])|(?:4[5-79])|(?:5[0-35-9])|(?:6[5-7])|(?:7[0-8])|(?:8[\d])|(?:9[189]))\d{8}$/
        ).test(value)
      ) {
        return callback(new Error("手机号码格式错误"));
      } else {
        callback();
      }
    };
    var validateauthCode = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("验证码不能为空"));
      } else {
        callback();

        // this.$API.personalUser
        //   .verificationCAPTCHA({
        //     mobile: this.ruleForm.number,
        //     captcha: this.ruleForm.authCode,
        //   })
        //   .then((res) => {
        //     if (!res.data.data.result) {
        //       return callback(new Error("验证码错误"));
        //     } else {
        //       callback();
        //     }
        //   });
      }
    };
    return {
      quality: [
        {
          icon: "login_pinzhi",
          title: "正品汇商城  品质放心",
          content: "全称抄码跟踪",
        },
        {
          icon: "login_shandian",
          title: "闪电配送 安全到达",
          content: "就近仓配送",
        },
        {
          icon: "login_tuihuo",
          title: "部分商品 无忧退货",
          content: "售后更放心",
        },
        {
          icon: "login_pinlei",
          title: "品类齐全 一站式进货",
          content: "酒水饮料 应有尽有",
        },
      ],
      bottomNav: [
        {
          title: "关于我们",
          content: [
            {
              content: "关于正品汇",
              url: "#/AboutUs",
            },
            {
              content: "联系正品汇",
              url: "#",
            },
            {
              content: "正品汇文化",
              url: "#",
            },
          ],
        },
        {
          title: "新手入门",
          content: [
            {
              content: "如何注册",
              url: "#",
            },
            {
              content: "购物流程",
              url: "#",
            },
            {
              content: "服务协议",
              url: "#",
            },
          ],
        },
        {
          title: "会员服务",
          content: [
            {
              content: "售后服务",
              url: "#",
            },
            {
              content: "退还流程",
              url: "#",
            },
          ],
        },
        {
          title: "支付/配送",
          content: [
            {
              content: "配送方式",
              url: "#",
            },
            {
              content: "配送细则",
              url: "#",
            },
            {
              content: "付款方式",
              url: "#",
            },
          ],
        },
        {
          title: "帮助中心",
          content: [
            {
              content: "常见问题",
              url: "#",
            },
            {
              content: "忘记密码",
              url: "#",
            },
            {
              content: "订单处理",
              url: "#",
            },
          ],
        },
      ], //底部导航
      clause: false, //条款
      passwordShow: false, //密码显示
      method: true, //短信验证登录切换
      Captcha: false, //验证码倒计时
      ruleForm: {
        accountNumber: "", //账号
        password: "", //密码
        number: "",
        authCode: "", //验证码
      },
      rules: {
        accountNumber: [
          { required: true, message: "请输入账号", trigger: "blur" },
        ],
        number: [{ validator: validateNumber, trigger: "blur" }],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
        authCode: [{ validator: validateauthCode, trigger: "blur" }],
      },
    };
  },
  watch: {
    method: function (val, oldVal) {
      this.$refs.ruleForm.resetFields();
    },
  },
  mounted() {},

  methods: {
    login() {
      if (this.clause) {
        let validateFieldList = [];
        let FormName;
        this.method
          ? (FormName = ["accountNumber", "password"])
          : (FormName = ["number", "authCode"]);
        this.$refs.ruleForm.validateField(FormName, async (valid) => {
          if (!valid) {
            validateFieldList.push(valid);
            if (
              validateFieldList.length == 2 &&
              validateFieldList.every((item) => item === "")
            ) {
              this.loginAPI();
            }
          } else {
            console.log("error submit!!");
            return false;
          }
        });
      } else {
        this.$message({
          message: "请勾选服务条款",
          type: "warning",
          duration: 1500,
          offset: 420,
        });
      }
    },
    async loginAPI() {
      const data = await this.$API.login.login({
        username: this.method
          ? this.ruleForm.accountNumber
          : this.ruleForm.number,
        password: this.method ? this.ruleForm.password : this.ruleForm.authCode,
      });
      if (data.data.code === "SUCCESS") {
        setToken(data.data.data.token);
        this.$message({
          type: "success",
          message: "登录成功",
          offset: 420,
        });
        this.$router.push("/Home");
        // console.log(data.data.data.token);
      } else {
        this.$message({
          type: "error",
          message: data.data.msg,
          offset: 420,
          duration: 1500,
        });
      }
      // USER_NOT_FOUND 用户未找到
      // BAD_CREDENTIALS 密码错误
      // SUCCESS 成功
    },
    CaptchaMethod() {
      if (!this.Captcha) {
        this.$refs.ruleForm.validateField(["number"], async (valid) => {
          if (!valid && !this.Captcha) {
            await this.$API.personalUser
              .sendCAPTCHA({
                mobile: this.ruleForm.number,
              })
              .then((res) => {
                this.$message({
                  message: "发送成功",
                  type: "success",
                  offset: 420,
                });
                let t = 59;
                this.Captcha = `${t}s后重新获取`;
                this.t = setInterval(() => {
                  t--;
                  this.Captcha = `${t}s后重新获取`;
                  if (t < 1) {
                    clearInterval(this.t);
                    this.Captcha = false;
                  }
                }, 1000);
              })
              .catch(() => {
                this.$message({
                  message: "发送失败",
                  type: "error",
                  offset: 420,
                });
              });
          }
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#login {
  .w1200 {
    width: 1200px;
  }
  .flex_jc_center {
    display: flex;
    justify-content: center;
    position: relative;
    z-index: 1;
  }

  header {
    background-color: #fff;
    .top {
      display: flex;
      padding: 25px 0;
      position: relative;
      img {
        width: 70px;
        height: 70px;
        margin-right: 16px;
        cursor: pointer;
      }
      .title {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 6px 0;
        cursor: pointer;
        h1 {
          height: 37px;
          font-size: 32px;
          font-family: FZDaBiaoSong-B06S-Regular, FZDaBiaoSong-B06S;
          color: #333333;
          font-weight: 400;
        }
        h2 {
          height: 17px;
          font-size: 15px;
          font-family: Arial-Regular, Arial;
          color: #666666;
        }
      }
      > p {
        font-size: 24px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        color: #333333;
        line-height: 70px;
        margin-left: 56px;
      }
      .go_login {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        font-size: 16px;
        color: #333333;
        display: flex;
        p {
          margin-left: 30px;
        }
        a {
          color: var(--subjectColor);
        }
        a:hover {
          text-decoration: underline;
        }
      }
    }
  }
  main {
    background-color: #fffaf8;
    .login_box {
      height: 668px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      section {
        background-color: #fff;
        width: 360px;
        box-shadow: 0px 2px 14px 1px rgba(0, 0, 0, 0.08);
        .method {
          display: flex;
          justify-content: space-around;
          padding: 16px 0;
          border-bottom: 1px solid #eeeeee;
          p {
            font-size: 18px;
            font-weight: bold;
            color: var(--subjectColor);
            cursor: default;
          }
        }
        .method::after {
          content: "";
          position: absolute;
          border-right: 1px solid #eeeeee;
          height: 24px;
        }
        .login_cont {
          padding: 36px 24px 30px;
          .el-form-item {
            margin-bottom: 20px;
          }
          ::v-deep .el-input {
            border-radius: 4px;
            transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
            .el-input-group__prepend {
              background: #f5f5f5;
              padding: 0 13px;
            }
            .el-input__suffix {
              right: 12px;
              line-height: 40px;
              user-select: none;
              .el-input__suffix-inner {
                cursor: pointer;
              }
            }
          }
          .el-input:hover {
            border-color: #c0c4cc;
          }
          .other_ctions {
            margin: 16px 0 24px 0;
            display: flex;
            justify-content: space-between;
            -moz-user-select: none;
            -ms-user-select: none;
            -webkit-user-select: none;
            user-select: none;
            a {
              height: 16px;
              font-size: 12px;
              color: #333333;
              cursor: pointer;
            }
          }
          .clause {
            font-size: 12px;
            display: flex;
            input[type="checkbox"] {
              margin: 2px;
            }
            div {
              line-height: 17px;
              color: #333333;
              margin-left: 8px;
              a {
                color: #208cf8;
              }
              a:hover {
                text-decoration: underline;
              }
            }
          }
          button {
            width: 100%;
            height: 40px;
            background: linear-gradient(132deg, #ff7300 0%, #fb5d20 100%);
            border-radius: 4px;
            border: none;
            font-size: 16px;
            color: #ffffff;
            margin-top: 30px;
            cursor: pointer;
          }
        }
        .sign_up {
          text-align: center;
          background: #fcfcfc;
          padding: 10px 0;
          border-top: 1px solid #f5f5f5;
          line-height: 20px;
          font-size: 14px;
          .svg-icon {
            margin-right: 4px;
          }
          a {
            color: #fb5d20;
          }
        }
      }
    }
  }
  footer {
    background-color: #fff;
    border-top: 1px solid #eeeeee;
    .bottom {
      padding: 30px 20px;
      .introduction {
        margin-bottom: 31px;
        .introduction_list {
          display: flex;
          .svg-icon {
            font-size: 40px;
            margin-right: 12px;
            color: #666;
            transition: all 1s;
          }
          div {
            padding: 1px 0;
            cursor: default;
            p {
              height: 21px;
              font-size: 16px;
              color: #333333;
              transition: all 0.3s;
            }
            span {
              height: 19px;
              font-size: 14px;
              color: #999999;
              transition: all 0.3s;
            }
          }
        }
        .introduction_list:hover {
          .svg-icon {
            transform: rotateY(360deg);
            color: var(--subjectColor);
          }
          p,
          span {
            color: var(--subjectColor) !important;
          }
        }
      }
      .flex_sb {
        display: flex;
        justify-content: space-between;
      }
      nav {
        .nav_list {
          display: flex;
          flex-direction: column;
          p {
            height: 21px;
            font-size: 16px;
            color: #333333;
            margin-bottom: 16px;
          }
          a {
            height: 16px;
            font-size: 12px;
            color: #666666;
            margin-bottom: 12px;
          }
          a:hover {
            color: var(--subjectColor);
          }
          a:last-child {
            margin: 0;
          }
        }
      }
    }
  }
  .copyright {
    height: 50px;
    background: #3f3c3c;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    line-height: 20px;
    font-size: 12px;
    span {
      padding: 0 8px;
    }
    a {
      color: #fff;
    }
  }
}
</style>